import styled, { DefaultTheme } from 'styled-components';

const PageWrapper = styled.div`
  max-width: ${(p) => p.theme.maxWidth};
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
`;

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? { $bg: theme.colors.main.value, 'data-cy': 'page-wrapper' }
    : { $bg: theme.colors.black[900], 'data-cy': 'page-wrapper' };

export const ModalContainerWrapper = styled.div.attrs(mapColors)`
  background-color: ${(p) => p.$bg};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

export default PageWrapper;
