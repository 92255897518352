import Field, { Content } from './form-interpretor.types';
import * as yup from 'yup';
import locale from './form-interpretor.locale.json';

const getAllFields = (contents: Content[]): Field[] => {
  return contents.map((content) => content.fields).flat();
};

export const getInitialValues = (content: Content[]) => {
  return Object.fromEntries(
    getAllFields(content).map((field) => [field.name, field.default || '']),
  );
};

export const getValidationSchema = (content: Content[]) => {
  yup.setLocale(locale);
  const yupContent = Object.fromEntries(
    getAllFields(content).map((field) => [field.name, field.schema(yup)]),
  );
  return yup.object(yupContent).required();
};
