import BaseCheckBox from '@material-ui/core/Checkbox';
import { FormControlLabel } from './checkbox.styles';

interface Props {
  label: React.ReactNode;
  value?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export type CheckboxProps = Props;

const Checkbox: React.FC<Props> = ({ label, onChange, value }) => {
  return (
    <FormControlLabel
      control={
        <BaseCheckBox onChange={onChange} checked={value} color="primary" />
      }
      label={label}
    />
  );
};

export default Checkbox;
