import FullLogo from '@atoms/FullLogo';
import Footer from '@organisms/Footer';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import InsertSupport from './pages/InsertSupport';
import InsertWaypoint from './pages/InsertWaypoint';
import { ModalContainerWrapper } from './pages/wrappers';
import { Container } from '@atoms/ModalStyles';
import Nav from '@organisms/Nav';
import Login from './pages/Login';
import { useRecoilValue } from 'recoil';
import LoginAtom from './pages/Login/login.atom';
import { useEffect } from 'react';

const Routes = () => {
  const token = useRecoilValue(LoginAtom);
  const history = useHistory();
  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, [token, history]);
  return (
    <ModalContainerWrapper>
      <FullLogo />
      <Container>
        {token && <Nav />}
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/">
            <Redirect to="insert-waypoint" />
          </Route>
          <Route exact path="/insert-waypoint" component={InsertWaypoint} />
          <Route exact path="/insert-support" component={InsertSupport} />
        </Switch>
      </Container>
      <Footer />
    </ModalContainerWrapper>
  );
};

export default Routes;
