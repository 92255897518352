import styled, { DefaultTheme } from 'styled-components';

const lightScrollColors = {
  track: '#f1f1f1',
  thumb: '#888',
  'thumb-hover': '#555',
};
const darkScrollColors = {
  track: '#737373',
  thumb: '#bfbfbf',
  'thumb-hover': '#dbdbdb',
};

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? {
        $scroll: lightScrollColors,
      }
    : {
        $scroll: darkScrollColors,
      };

export const Messages = styled.div.attrs(mapColors)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px;
  & .MuiAlert-root {
    width: 100%;
    margin: 5px 0;
  }
  max-height: 200px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(p) => p.$scroll.track};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.$scroll.thumb};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.$scroll['thumb-hover']};
  }
`;
