import styled, { css, DefaultTheme } from 'styled-components';
import { DefaultContainer } from '@atoms/DefaultContainer/default-container.component';
import { BaseField as Field } from '@atoms/Field/field.styles';
import { Link as BaseLink } from 'react-router-dom';

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? {
        $header: theme.colors.black[900],
        $subheader: theme.colors.black[800],
      }
    : {
        $header: theme.colors.white[1000],
        $subheader: theme.colors.white[900],
      };

export const Container = styled(DefaultContainer)`
  max-width: 800px;
  min-width: unset;
  border-radius: 20px;
  padding: 20px 20px;
  margin: 20px 0;
  z-index: 100;

  @media (max-width: 800px) {
    max-width: 720px;
    margin: 0 auto;
  }

  & .MuiButton-containedPrimary {
    height: 58px;
    padding: 0 40px;
    font-weight: bold;
  }
`;

export const Header = styled.h1.attrs(mapColors)`
  text-align: center;
  font-size: 4rem;
  color: ${(p) => p.$header};
  margin: 10px 0;
`;

export const SubHeader = styled.h4.attrs(mapColors)`
  text-align: center;
  font-size: 2rem;
  color: ${(p) => p.$subheader};
  margin: 10px 0;
`;

export const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 20px;

  ${Field} {
    margin-right: 10px;
  }
  ${Field}:last-of-type {
    margin-right: 0;
  }
  & .MuiButton-containedPrimary {
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    ${Field} {
      margin-bottom: 10px;
    }
    ${Field}:last-of-type {
      margin-bottom: 0;
    }
    & .MuiButton-containedPrimary {
      width: 100%;
      margin-left: unset;
      margin-top: 10px;
      padding: 10px 20px;
      font-size: 2rem;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
`;

export const Highlight = styled.span`
  color: ${(p) => p.theme.colors.main.value};
  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const Link = styled(BaseLink)`
  font-size: 1.8rem;
  color: ${(p) => p.theme.colors.main.value};
  margin: 0.8rem 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`;
