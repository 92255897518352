import { GlobalStyles } from 'src/theme/global';
import ThemeDeps from './theme-deps';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept();
  }
}

const Deps: React.FC = ({ children }) => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ThemeDeps>
          <Router>{children}</Router>
          <GlobalStyles />
        </ThemeDeps>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default Deps;
