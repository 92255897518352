import { Container } from './nav.styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { pages } from 'src/pages';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';

const Nav = () => {
  const history = useHistory();
  const [selectedIndex, selectIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    pages.forEach((page, index) => {
      const isExact = matchPath(location.pathname, page.route);
      if (isExact) {
        selectIndex(index);
      }
    });
  }, [selectIndex, location]);

  const handleTo = useCallback(
    (route: string) => () => {
      history.push(route);
    },
    [history],
  );
  return (
    <Container>
      <Tabs variant="fullWidth" value={selectedIndex}>
        {pages.map((page) => (
          <Tab
            key={page.route}
            label={page.name}
            onClick={handleTo(page.route)}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default Nav;
