import ReactDOM from 'react-dom';
import Deps from './deps';
import Routes from './routes';

ReactDOM.render(
  <Deps>
    <Routes />
  </Deps>,
  document.querySelector('#root'),
);
