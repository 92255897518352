import Field, { FieldProps as BaseFieldProps } from './field.component';
import { FormikContextType, useField, useFormikContext } from 'formik';
import { useCallback } from 'react';

interface Props {
  name: string;
  onListen?: (value: string, formik: FormikContextType<unknown>) => void;
}

export type FieldProps = Props & BaseFieldProps;

const FormikField: React.FC<FieldProps> = ({ onListen, name, ...props }) => {
  const [{ onChange, ...field }, meta] = useField({ name: name });
  const formik = useFormikContext();
  const handleChange: FieldProps['onChange'] = useCallback(
    (e) => {
      onChange(e);
      if (onListen) {
        onListen(e.target.value, formik);
      }
    },
    [onListen, onChange, formik],
  );
  return (
    <Field
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      onChange={handleChange}
      {...props}
    />
  );
};

export default FormikField;
