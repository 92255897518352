import { MenuItem, TextFieldProps } from '@material-ui/core';
import { forwardRef } from 'react';
import { BaseField } from './field.styles';

type BaseFieldProps = TextFieldProps;

export type Option =
  | {
      label: string;
      value: string;
    }
  | string;

interface Props {
  options?: Option[];
  namespace?: string;
}

const mapOptions = (options: Option[]) =>
  options.map((option) => {
    const label = typeof option === 'object' ? option.label : option;
    const value = typeof option === 'object' ? option.value : option;
    const key = typeof option === 'object' ? option.label : option;
    return (
      <MenuItem key={key} value={value}>
        {label}
      </MenuItem>
    );
  });

export type FieldProps = Props & BaseFieldProps;

const Field: React.ForwardRefExoticComponent<FieldProps> = forwardRef(
  ({ options, namespace, ...props }, ref) => {
    return (
      <BaseField
        ref={ref}
        variant="filled"
        fullWidth
        {...props}
        children={options ? mapOptions(options) : props.children}
      />
    );
  },
);

export default Field;
