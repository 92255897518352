import Checkbox, { CheckboxProps } from '@atoms/Checkbox/checkbox.component';
import { FieldsRow } from '@atoms/ModalStyles';
import { Collapse } from '@material-ui/core';
import { useCallback, useState } from 'react';
import {
  FieldsContent,
  HiddeableFieldsContent,
} from './form-interpretor.types';
import InformativeField from './InformativeField/informative-fields.component';
import { useQuery } from 'react-query';

interface Strategy {
  fields: (content: FieldsContent) => JSX.Element;
  hiddeable: (content: HiddeableFieldsContent) => JSX.Element;
}

const strategies: Strategy = {
  fields: ({ fields }) => {
    return (
      <>
        {fields.map((field) => {
          const { data: options = [] } = useQuery(
            `options-${field.name}`,
            async () => {
              if (!field.options) return [];
              if (Array.isArray(field.options)) {
                return field.options;
              }
              const value = await field.options();
              return value;
            },
            { initialData: [] },
          );
          return (
            <FieldsRow key={field.name}>
              <InformativeField
                select={Boolean(field.options)}
                options={options}
                name={field.name}
                label={field.label}
                help={field.help || ''}
                onListen={field.onChange}
              />
            </FieldsRow>
          );
        })}
      </>
    );
  },
  hiddeable: ({ fields, message }) => {
    const [isShow, setIsShow] = useState(false);
    const handleChange: CheckboxProps['onChange'] = useCallback(
      (e) => {
        setIsShow(e.target.checked);
      },
      [setIsShow],
    );
    return (
      <>
        <FieldsRow>
          <Checkbox label={message} onChange={handleChange} value={isShow} />
        </FieldsRow>
        <Collapse in={isShow}>
          {strategies['fields']({ type: 'fields', fields })}
        </Collapse>
      </>
    );
  },
};

export default strategies;
