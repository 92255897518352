import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import LoginAtom from 'src/pages/Login/login.atom';
import { IconButton } from '@material-ui/core';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const LogoutButton = () => {
  const [token, setToken] = useRecoilState(LoginAtom);
  const logout = useCallback(() => {
    setToken(null);
  }, [setToken]);
  if (!token) return null;
  return (
    <IconButton onClick={logout}>
      <ExitToAppIcon />
    </IconButton>
  );
};

export default LogoutButton;
