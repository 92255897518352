import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const BaseField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 5px;
  }
  & .MuiFilledInput-underline:before {
    content: unset;
  }
  & .MuiFilledInput-underline:after {
    content: unset;
  }
`;
