import { Logo, Container } from './full-logo.styles';

const FullLogo = () => {
  return (
    <Container>
      <a href="https://www.abbiamolog.com/home" target="_blank">
        <Logo />
      </a>
    </Container>
  );
};

export default FullLogo;
