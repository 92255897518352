import { format } from 'date-fns';

const formatTime = (date: string | Date) => {
  try {
    return format(new Date(date), 'kk:mm:ss dd/MM/yy');
  } catch {
    return '';
  }
};

export default formatTime;
