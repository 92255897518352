import InsertSupport from './InsertSupport';
import InsertWaypoint from './InsertWaypoint';

export interface Page {
  component: React.FC;
  name: string;
  route: string;
}

export const pages: Page[] = [
  {
    route: '/insert-waypoint',
    component: InsertWaypoint,
    name: 'Inserir Ponto de Coleta',
  },
  {
    route: '/insert-support',
    component: InsertSupport,
    name: 'Inserir Ponto de Suporte',
  },
];
