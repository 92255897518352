import { withStyles } from '@material-ui/core';
import BaseTooltip from '@material-ui/core/Tooltip';
import styled, { DefaultTheme } from 'styled-components';

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? { $bg: theme.colors.white[1000], $color: theme.colors.black[1000] }
    : { $bg: theme.colors.black[1000], $color: theme.colors.white[1000] };

export const Tooltip = styled(
  withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[1],
      color: theme.palette.getContrastText(theme.palette.background.default),
      fontSize: '2rem',
      padding: '10px 20px',
      maxWidth: '500px',
    },
  }))(BaseTooltip),
).attrs((p) => ({ ...mapColors(p), enterDelay: 500, interactive: true }))``;

export default Tooltip;
