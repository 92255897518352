import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import useThemeToggler from 'src/hooks/useThemeToggler/use-theme-toggler.hook';
import { IconButton } from './theme-selector.styles';

const ThemeSelector = () => {
  const { themeType, setTheme } = useThemeToggler();
  const toggle = () => {
    setTheme(themeType === 'dark' ? 'light' : 'dark');
  };
  return (
    <IconButton onClick={toggle}>
      {themeType === 'dark' ? <Brightness4Icon /> : <Brightness7Icon />}
    </IconButton>
  );
};

export default ThemeSelector;
