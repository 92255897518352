import { useCallback, useState } from 'react';

const useFocus = () => {
  const [state, setState] = useState(false);
  const onFocus = useCallback(() => {
    setState(true);
  }, [setState]);

  const onBlur = useCallback(() => {
    setState(false);
  }, [setState]);
  return [state, { onFocus, onBlur }] as const;
};

export default useFocus;
