import { Content } from '@molecules/FormInterpretor/form-interpretor.types';

export interface Data {
  token: string;
}

export const contents: Content[] = [
  {
    type: 'fields',
    fields: [
      {
        name: 'token',
        label: 'Codigo de Acesso',
        schema: (yup) => yup.string().required(),
      },
    ],
  },
];
