import {
  createTheme as createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { useMemo } from 'react';
import { theme as rawTheme } from 'src/theme';
import { ThemeProvider } from 'styled-components';
import { ThemeToggleContext } from './hooks/useThemeToggler/use-theme-toggler.context';
import useThemeTogglerRoot from './hooks/useThemeToggler/use-theme-toggler.root';

const ThemeDeps: React.FC = ({ children }) => {
  const themeManagerRoot = useThemeTogglerRoot();

  const theme = useMemo(() => {
    return {
      ...rawTheme,
      type: themeManagerRoot.themeType as any,
    };
  }, [themeManagerRoot.themeType]);

  const muiTheme = useMemo(() => {
    return createMuiTheme({
      palette: {
        type: theme.type,
        primary: {
          main: theme.colors.main.value,
          contrastText: '#000',
        },
        secondary: {
          main: theme.colors.main.value,
          contrastText: '#000',
        },
        text: {
          primary:
            theme.type === 'light'
              ? theme.colors.black[1000]
              : theme.colors.white[1000],
        },
      },
      typography: {
        fontSize: 25,
      },
    });
  }, [theme]);
  return (
    <ThemeToggleContext.Provider value={themeManagerRoot}>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme as any}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default ThemeDeps;
