import styled, { DefaultTheme } from 'styled-components';

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? {
        $bg: theme.colors.white[1000],
        $h1: theme.colors.black[900],
        $h2: theme.colors.black[800],
      }
    : {
        $bg: theme.colors.black[800],
        $h1: theme.colors.white[1000],
        $h2: theme.colors.white[900],
      };

export const DefaultContainer = styled.div.attrs(mapColors)`
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(p) => p.$bg};
  box-shadow: ${(p) => p.theme.type === 'light' && p.theme.defaultShadow};
  width: 100%;
  max-width: 500px;
  padding: 1rem 0;
`;
