import BaseButton from '@material-ui/core/Button';
import styled from 'styled-components';

export const StyledButton = styled(BaseButton)`
  &.MuiButton-containedPrimary:not(.Mui-disabled) {
    border: 2px solid
      ${(p) =>
        p.theme.type === 'light'
          ? p.theme.colors.main.contrast
          : p.theme.colors.main.value};
  }

  &.MuiSvgIcon-root {
    fill: ${(p) => p.theme.colors.main.value};
  }

  &.MuiButton-outlinedPrimary {
    .MuiButton-label {
      color: ${(p) => p.theme.colors.main.value};
    }
  }

  &.MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
    border-right-color: rgba(0, 0, 0, 0.1);
  }
`;
