import FormInterpretor, {
  FormInterpretorProps,
  Message,
} from '@molecules/FormInterpretor/form-interpretor.component';
import { AxiosError } from 'axios';
import { postSupportPoint } from 'src/service/SupportPoint';
import { contents, Data } from './insert-support.contents';

const UnknowError: Message = {
  body: `Erro Desconhecido`,
  title: 'Erro',
  severity: 'error',
};

const NotFoundError: Message = {
  body: `Rota Não Encontrada`,
  title: 'Erro',
  severity: 'error',
};

const handleSubmit: FormInterpretorProps['handleSubmit'] = async (
  data: Data,
) => {
  try {
    await postSupportPoint(data);
  } catch (err) {
    if (!err.isAxiosError) return UnknowError;
    const error: AxiosError = err;
    if (error?.response?.status === 404) return NotFoundError;
    return UnknowError;
  }

  return {
    body: `Tudo certo, ponto de suporte ${data.supportpoint_id} adicionado na rota ${data.route_id}`,
    title: 'Sucesso!',
    severity: 'success',
  };
};

const InsertSupportForm = () => {
  return (
    <FormInterpretor
      handleSubmit={handleSubmit}
      contents={contents}
      header="Inserir ponto de suporte"
      subheader="Insira as informações pedidas abaixo para adicionar ponto de descarga como próxima parada de uma rota já existente"
    />
  );
};

export default InsertSupportForm;
