import { Fragment, useCallback, useMemo } from 'react';
import Button from '@atoms/Button';
import { Form, Formik } from 'formik';
import { FieldsRow, Header, SubHeader } from '@atoms/ModalStyles';
import { Content } from './form-interpretor.types';
import {
  getInitialValues,
  getValidationSchema,
} from './form-interpretor.utils';
import strategies from './form-interpretor.strategies';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { Collapse } from '@material-ui/core';
import { Messages } from './form-interpretor.styles';
import { useList } from 'react-use';
import formatTime from 'src/utils/formatTime';
import compareTime from 'src/utils/compareTime';
import { useRef } from 'react';

export interface Message {
  body: string;
  severity: 'error' | 'success';
  title: string;
}

interface Props {
  header: string;
  subheader: string;
  contents: Content[];
  handleSubmit: (body: any) => Message | Promise<Message>;
}

export type FormInterpretorProps = Props;

type LocalMessage = Message & { active: boolean; time: Date };

const FormInterpretor: React.FC<Props> = ({
  header,
  subheader,
  contents,
  handleSubmit,
}) => {
  const initialValues = useMemo(() => getInitialValues(contents), [contents]);
  const validationSchema = useMemo(
    () => getValidationSchema(contents),
    [contents],
  );
  const messagesRef = useRef<HTMLDivElement>(null);
  const [localMessages, { push }] = useList<LocalMessage>([]);
  const onSubmit = useCallback(
    async (data) => {
      const message = await handleSubmit(data);
      const newLocalMessage: LocalMessage = {
        ...message,
        active: true,
        time: new Date(),
      };
      push(newLocalMessage);
      messagesRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [push, handleSubmit],
  );
  return (
    <>
      <Header>{header}</Header>
      <SubHeader>{subheader}</SubHeader>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {contents.map((content, index) => (
              <Fragment key={index}>
                {strategies[content.type](content as any)}
              </Fragment>
            ))}
            <FieldsRow>
              <Button
                isLoading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                Enviar
              </Button>
            </FieldsRow>
          </Form>
        )}
      </Formik>
      <Collapse
        style={{ width: '100%' }}
        in={localMessages && localMessages.length > 0}
      >
        <Messages ref={messagesRef}>
          {localMessages
            ?.sort((d1, d2) => compareTime(d2.time, d1.time))
            .map((message, index) => (
              <Alert key={index} severity={message.severity} variant="outlined">
                <AlertTitle>{message.title}</AlertTitle>
                {message.body}
                <br />
                {`Horario da Tentativa: ${formatTime(message.time)}`}
              </Alert>
            ))}
        </Messages>
      </Collapse>
    </>
  );
};

export default FormInterpretor;
