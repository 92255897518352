type ConditionMatcher = (value: any) => boolean;

type Format = (value: any) => any;

const replaceAllThatMeetCondition = (
  obj: Record<string, any>,
  conditionMatcher: ConditionMatcher,
  format: Format,
) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [
          key,
          value.map((v) => {
            if (typeof v === 'object') {
              return replaceAllThatMeetCondition(v, conditionMatcher, format);
            }
            return v;
          }),
        ];
      }
      if (typeof value === 'object') {
        return [
          key,
          replaceAllThatMeetCondition(value, conditionMatcher, format),
        ];
      }
      const isMatched = conditionMatcher(value);
      return [key, isMatched ? format(value) : value];
    }),
  );
};

export default replaceAllThatMeetCondition;
