import { Content } from '@molecules/FormInterpretor/form-interpretor.types';
import { getSupportPoints } from 'src/service/SupportPoint';

export interface Data {
  route_id: string;
  supportpoint_id: string;
}

export const contents: Content[] = [
  {
    type: 'fields',
    fields: [
      {
        name: 'route_id',
        label: 'Id da Rota',
        schema: (yup) => yup.string().required(),
        help: 'Identificador da Rota',
      },
      {
        name: 'supportpoint_id',
        label: 'Ponto de suporte',
        schema: (yup) => yup.string().required(),
        options: async () => {
          const supportPoints = await getSupportPoints();
          return supportPoints.map((supportPoint) => ({
            label: `(${supportPoint.id}): ${supportPoint.address}`,
            value: supportPoint.id,
          }));
        },
      },
    ],
  },
];
