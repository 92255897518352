import { Content } from '@molecules/FormInterpretor/form-interpretor.types';
import { FormikContextType } from 'formik';
import sumHours from 'src/utils/sumHours';

const newNumeredArray = (length: number): number[] => {
  return [...new Array(length)].map((_, index) => index + 1);
};

const toHour = (number: number) => `${number < 10 ? `0${number}` : number}:00`;

const timeWindowOptions = newNumeredArray(24).map(toHour);

export interface Data {
  route_id: string;
  customer_id: string;
  time_window_start?: string;
  time_window_end?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
}

export const contents: Content[] = [
  {
    type: 'fields',
    fields: [
      {
        name: 'route_id',
        label: 'Id da Rota',
        schema: (yup) => yup.string().required(),
        help: 'Identificador da Rota no Foxtrot',
      },
      {
        name: 'customer_id',
        label: 'Id do Cliente',
        schema: (yup) => yup.string().required(),
        help: 'Identificador do Cliente',
      },
      {
        name: 'time_window_start',
        label: 'Janela de Atendimento Inicial (Opcional)',
        help: 'Horario Inicial da Janela de Atendimento',
        schema: (yup) => yup.string().notRequired(),
        options: timeWindowOptions,
        onChange: (value, formik: FormikContextType<Data>) => {
          const getHourValue = (v: any): number => {
            try {
              return Number(v.split(':')[0]);
            } catch {
              return 0;
            }
          };
          const timeWindowStart = value;
          const timeWindowEnd = formik.values.time_window_end;
          const timeWindowStartIsNotEmpty = timeWindowStart !== '';
          const timeWindowEndIsEmpty = timeWindowStart === '';
          const timeWindowEndIsNotValid =
            getHourValue(timeWindowEnd) < getHourValue(timeWindowStart);
          if (
            timeWindowStartIsNotEmpty &&
            (timeWindowEndIsEmpty || timeWindowEndIsNotValid)
          ) {
            const timeWindowStartHourValue = getHourValue(timeWindowStart);
            const newHour = sumHours(timeWindowStartHourValue, 4);
            formik.setFieldValue('time_window_end', toHour(newHour));
          }
        },
      },
      {
        name: 'time_window_end',
        label: 'Janela de Atendimento Final (Opcional)',
        help: 'Horario Final da Janela de Atendimento (Obrigatorio se o horario inicial for preenchido)',
        schema: (yup) =>
          yup
            .string()
            .when('time_window_start', (timeWindowStart, field) =>
              timeWindowStart ? field.required() : field,
            ),
        options: timeWindowOptions,
      },
    ],
  },
];
