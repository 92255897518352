import { StyledButton } from './button.styles';
import { useMemo } from 'react';
import Tooltip from '../Tooltip';

type BaseProps = Parameters<typeof StyledButton>[0];

interface Props {
  isLoading?: boolean;
  tooltip?: string;
}

export type ButtonProps = Props & BaseProps;

const Button: React.FC<ButtonProps> = ({ isLoading, tooltip, ...p }) => {
  const buttonContent = useMemo(
    () => (
      <StyledButton
        variant="contained"
        color="primary"
        {...p}
        disabled={isLoading ? true : p.disabled}
        children={isLoading ? 'Carregando' : p.children}
      />
    ),
    [p, isLoading],
  );
  return tooltip ? (
    <Tooltip title={tooltip || ''}>
      <span>{buttonContent}</span>
    </Tooltip>
  ) : (
    buttonContent
  );
};

export default Button;
