import BaseFormControlLabel from '@material-ui/core/FormControlLabel';
import styled, { DefaultTheme } from 'styled-components';

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? { $color: theme.colors.black[700] }
    : { $color: theme.colors.white[1000] };

export const FormControlLabel = styled(BaseFormControlLabel).attrs(mapColors)`
  & .MuiFormControlLabel-root {
    justify-content: center;
  }

  & .MuiIconButton-label {
    color: ${(p) => p.theme.colors.main.value};
  }

  & .MuiFormControlLabel-label {
    font-weight: 600;
    color: ${(p) => p.$color};
    cursor: pointer;
    user-select: none;
  }
`;
