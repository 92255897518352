const compareTime = (
  n1: string | number | Date,
  n2: string | number | Date,
) => {
  const a = new Date(n1).valueOf();
  const b = new Date(n2).valueOf();
  return a < b ? -1 : a === b ? 0 : 1;
};

export default compareTime;
