import FormInterpretor, {
  FormInterpretorProps,
} from '@molecules/FormInterpretor/form-interpretor.component';
import { contents, Data } from './insert-waypoint.contents';
import { postWaypoint } from 'src/service/Waypoint';
import stripProperties from 'src/utils/stripProperties';
import { AxiosError } from 'axios';

const handleSubmit: FormInterpretorProps['handleSubmit'] = async (
  data: Data,
) => {
  const {
    route_id,
    latitude,
    longitude,
    customer_id,
    address,
    time_window_end,
    time_window_start,
  }: typeof data = stripProperties(data);
  try {
    await postWaypoint(route_id, {
      customer_id,
      address,
      time_window_end,
      time_window_start,
      location: latitude && longitude ? { latitude, longitude } : undefined,
    });
  } catch (err: any) {
    if (err.isAxiosError) {
      const error: AxiosError = err;
      const status: number | undefined = error.response?.status;
      const message: string | undefined = error.response?.data.message;
      if (status && status === 404) {
        return {
          body: `Rota ${route_id} não encontrada.`,
          title: 'Erro!',
          severity: 'error',
        };
      }
      if (message) {
        return {
          body: message,
          title: 'Erro!',
          severity: 'error',
        };
      }
    }
  }
  return {
    body: `Tudo certo, ponto de coleta adicionado na rota ${route_id}`,
    title: 'Sucesso!',
    severity: 'success',
  };
};

const InsertWaypoint = () => {
  return (
    <FormInterpretor
      handleSubmit={handleSubmit}
      contents={contents}
      header="Inserir ponto de coleta na rota"
      subheader="Insira as informações pedidas abaixo para adicionar ponto de coleta à uma rota já existente"
    />
  );
};

export default InsertWaypoint;
