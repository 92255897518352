import styled, { DefaultTheme } from 'styled-components';

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? {
        $bg: theme.colors.main.value,
        $color: theme.colors.main.contrast,
      }
    : { $bg: theme.colors.black[950], $color: theme.colors.white[1000] };

export const Container = styled.div.attrs(mapColors)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.$color};
`;

export const Copyright = styled.p.attrs(mapColors)`
  font-size: 1.6rem;
  margin: 5px 0;
  color: ${(p) => p.$color};
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  z-index: 10;
`;
