import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use((config) => {
  const persist = localStorage.getItem('recoil-persist');
  if (!persist) return config;
  const token = JSON.parse(persist)?.token || null;
  if (!token) return config;
  config.headers.authorization = token;
  return config;
});

export default api;
