import ThemeSelector from '@atoms/ThemeSelector/theme-selector.component';
import { Container, Copyright, Wrapper } from './footer.styles';
import LogoutButton from './LogoutButton';

const RegisterFooter = () => {
  return (
    <Wrapper>
      <Container>
        <ThemeSelector />
        <LogoutButton />
      </Container>
      <a href="https://www.abbiamolog.com/home" target="_blank">
        <Copyright>Copyright - Abbiamo {new Date().getFullYear()}</Copyright>
      </a>
    </Wrapper>
  );
};

export default RegisterFooter;
