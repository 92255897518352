import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 10px 20px;
  height: fit-content;
  z-index: 10;
`;

export const Logo = styled.img.attrs((p) => ({
  src:
    p.theme.type === 'light'
      ? 'logos/full_abbiamo.svg'
      : 'logos/full_abbiamo_dark.svg',
}))`
  width: 100%;
`;
