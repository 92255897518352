import { useState } from 'react';
import { useHover as useGestureHover } from '@use-gesture/react';

const useHover = () => {
  const [state, setState] = useState(false);
  const bind = useGestureHover(({ hovering }) => setState(Boolean(hovering)));
  return [state, bind] as const;
};

export default useHover;
