import BaseInfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';

export const InfoIcon = styled(BaseInfoIcon)`
  &.MuiSvgIcon-root {
    fill: ${(p) =>
      p.theme.type === 'light'
        ? p.theme.colors.black[900]
        : p.theme.colors.white[900]};
  }
`;
