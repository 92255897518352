export const theme = {
  borderRadius: '5px',
  type: 'light',
  maxWidthNumber: 1600,
  maxWidth: '1600px',
  defaultShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
  colors: {
    main: {
      value: '#6ED796',
      contrast: '#000',
    },
    green: {
      500: '#6ED796',
    },
    red: {
      500: '#f44336',
    },
    black: {
      600: '#565c5e',
      700: '#3C4042',
      800: '#2D3133',
      900: '#202124',
      950: '#101010',
      1000: '#000',
    },
    white: {
      500: '#F0F0F0',
      700: '#9A9A9A',
      900: '#b7b7b7',
      950: '#DDDDDD',
      1000: '#fff',
    },
    gray: {
      300: '#efefef',
      500: '#DEDEDE',
      800: '#3e3e3e',
    },
  },
} as const;

export type ITheme = typeof theme;
