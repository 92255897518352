import replaceAllThatMeetCondition from './replaceAllThatMeetCondition';

const stripProperties = (obj: any) => {
  return replaceAllThatMeetCondition(
    obj,
    (v) => typeof v === 'string' && v === '',
    () => undefined,
  );
};

export default stripProperties;
