import InputAdornment from '@material-ui/core/InputAdornment';
import { Fade } from '@material-ui/core';

import { useFocus, useHover } from 'src/hooks/useGestures';
import Field, { FieldProps } from '@atoms/Field/field.formik';
import Tooltip from '@atoms/Tooltip';
import { InfoIcon } from './informative-fields.styles';

interface BaseProps {
  help: string;
}

type Props = BaseProps & FieldProps;

const InformativeField: React.FC<Props> = ({ help, ...props }) => {
  const [isHovering, bindHover] = useHover();
  const [isFocused, bindFocus] = useFocus();
  return (
    <Field
      InputProps={{
        ...bindFocus,
        ...bindHover(),
        endAdornment: (
          <Fade in={(isHovering || isFocused) && Boolean(help)}>
            <Tooltip title={help}>
              <InputAdornment position="end">
                <InfoIcon />
              </InputAdornment>
            </Tooltip>
          </Fade>
        ),
      }}
      {...props}
    />
  );
};

export default InformativeField;
